import { writable } from "svelte/store";
export function getStudentLinkFromHistoryItem(entry) {
    if (entry["Email (from Students"]) {
    }
    else {
        return undefined;
    }
}
export async function lookupSignoutHistory({ asset, student, isLatest, onlyOut, } = {}) {
    let params = { mode: "signoutHistory" };
    if (asset) {
        params.assetTag = asset["Asset Tag"];
    }
    if (student) {
        params.lasid = student.LASID;
    }
    if (onlyOut) {
        params.onlyOut = true;
    }
    if (isLatest) {
        params.isLatest = true;
    }
    let paramString = new URLSearchParams(params);
    let response = await fetch("/.netlify/functions/index?" + paramString);
    let json = await response.json();
    return json.map((item) => (Object.assign(Object.assign({}, item.fields), { _id: item.id })));
}
export let fetching = writable(false);
export let fullHistory = writable([]);
export async function fetchFullHistory(onlyOut = false) {
    let $fullHistory = await lookupSignoutHistory({ onlyOut, isLatest: true });
    fullHistory.set($fullHistory);
}
