import { get } from "svelte/store";
import { user } from "./user";
function splitIntoTens(lst) {
    let copy = lst.slice();
    let batches = [];
    while (copy.length > 10) {
        let batch = copy.slice(0, 10);
        copy = copy.slice(10);
        batches.push(batch);
    }
    if (copy.length) {
        batches.push(copy);
    }
    return batches;
}
export async function updateNotifications(updates) {
    if (updates.length > 10) {
        let batched = splitIntoTens(updates);
        let results = [];
        for (let b of batched) {
            console.log("Working on batch");
            results = [...results, ...(await updateNotifications(b))];
            console.log("Have results:", results);
        }
        return results;
    }
    let params = { mode: "notifications" };
    let response = await fetch("/.netlify/functions/index?" + new URLSearchParams(params), {
        method: "PATCH",
        body: JSON.stringify(updates),
    });
    let json = await response.json();
    return json;
}
export async function getNotifications() {
    let params = {
        mode: "notifications",
        //records : notifications,
    };
    let response = await fetch("/.netlify/functions/index?" + new URLSearchParams(params), {
        method: "GET",
    });
    let json = await response.json();
    return json;
}
export async function createNotifications(notifications) {
    if (notifications.length > 10) {
        let batched = splitIntoTens(notifications);
        let results = [];
        for (let b of batched) {
            console.log("Working on batch");
            results = [...results, ...(await createNotifications(b))];
            console.log("Have results:", results);
        }
        return results;
    }
    let params = {
        mode: "notifications",
        //records : notifications,
    };
    let $user = get(user);
    console.log("Firing off request to create notifications", notifications);
    let response = await fetch("/.netlify/functions/index?" + new URLSearchParams(params), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(notifications),
    });
    let json = await response.json();
    return json;
}
