import { writable } from "svelte/store";
export const messagesStore = writable({});
export async function getMessages() {
    let params = { mode: "message" };
    let paramString = new URLSearchParams(params);
    let response = await fetch("/.netlify/functions/index?" + paramString);
    let json = await response.json();
    console.log("Got message data:", json);
    messagesStore.update(($messageStore) => {
        $messageStore = {};
        for (let result of json) {
            $messageStore[result.fields.ID] = Object.assign(Object.assign({}, result.fields), { _id: result.id });
        }
        console.log("messageStore:", JSON.stringify($messageStore));
        return $messageStore;
    });
    return json;
}
