import { writable } from "svelte/store";
//import type { Writable } from "svelte/store";
/* export const contractStore: Writable<{
  [key: number]: Contract;
}> = writable({});
 */
export const contactStore = writable({});
export function getEmails(contact) {
    let emails = [];
    for (let i = 1; i < 8; i++) {
        let field = `Contact${i}Email`;
        if (contact[field]) {
            emails.push(contact[field]);
        }
    }
    return emails;
}
export async function getContacts() {
    let params = { mode: "contact" };
    let paramString = new URLSearchParams(params);
    let response = await fetch("/.netlify/functions/index?" + paramString);
    let json = await response.json();
    console.log("Got contact data:", json);
    contactStore.update(($contactStore) => {
        $contactStore = {};
        for (let result of json) {
            let lasid = result.fields["LASID (from Student)"];
            $contactStore[lasid] = Object.assign(Object.assign({}, result.fields), { LASID: lasid, _id: result.id });
        }
        console.log("contactStore:", JSON.stringify($contactStore));
        return $contactStore;
    });
    return json;
}
