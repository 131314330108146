import { writable } from "svelte/store";
export let staffStore = writable({});
let searchCache = {};
export async function searchForStaff(name) {
    if (searchCache[name]) {
        return searchCache[name];
    }
    else {
        let response = await fetch("/.netlify/functions/index?mode=staff&name=" + encodeURIComponent(name));
        let json = await response.json();
        console.log("Got staff data", json);
        const results = [];
        staffStore.update(($staffStore) => {
            for (let result of json) {
                const staffObject = Object.assign(Object.assign({}, result.fields), { _id: result.id });
                results.push(staffObject);
                $staffStore[staffObject["Full Name"]] = staffObject;
                $staffStore[staffObject.Email] = staffObject;
                $staffStore[staffObject.psnOID] = staffObject;
                $staffStore[staffObject._id] = staffObject;
            }
            return $staffStore;
        });
        searchCache[name] = results;
        return results;
    }
}
