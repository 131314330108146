import { writable, get } from "svelte/store";
export let studentsStore = writable({});
let cachedSearch = {};
export async function searchForStudent(name) {
    if (cachedSearch[name]) {
        return cachedSearch[name];
    }
    let response = await fetch("/.netlify/functions/index?mode=student&name=" + encodeURIComponent(name));
    let json = await response.json();
    console.log("Got data:", json);
    studentsStore.update(($studentsStore) => {
        for (let result of json) {
            $studentsStore[result.fields.LASID] = Object.assign(Object.assign({}, result.fields), { _id: result.id });
        }
        return $studentsStore;
    });
    cachedSearch[name] = json;
    return json;
}
export function getStudent(name) {
    for (let item of Object.values(get(studentsStore))) {
        if (item.Name == name) {
            return item;
        }
    }
}
