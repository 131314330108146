import "svelte/store";
let serialCache = {};
export async function getDeviceInfo(device) {
    if (serialCache[device.Serial]) {
        return serialCache[device.Serial];
    }
    let response = await fetch("/.netlify/functions/index?mode=google&serial=" +
        encodeURIComponent(device.Serial));
    console.log("Fetching device Info for ", device.Serial);
    if (response.status == 200) {
        let json = await response.json();
        console.log("getDeviceInfo returning", json);
        serialCache[device.Serial] = json.result;
        return json.result;
    }
}
let userCache = {};
export async function getDevicesForUser(user) {
    if (userCache[user.Email]) {
        return userCache[user.Email];
    }
    let response = await fetch("/.netlify/functions/index?mode=google&user=" +
        encodeURIComponent(user.Email));
    console.log("Fetching devices for ", user.Email);
    if (response.status == 200) {
        let json = await response.json();
        console.log("getDevicesForUser returning", json);
        userCache[user.Email] = json.result;
        return json.result;
    }
}
